import ReactDOM from 'react-dom'
import Typed from "typed.js";
import React, { Component, useEffect, useState, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import "./styles/app.scss";
import NavLogo from "./assets/frontimg/wuhanlab.9c66c41d.png";
import DiscordLogo from "./assets/frontimg/download.png";
import InstagramLogo from "./assets/frontimg/instagram.png";
import TwitterLogo from "./assets/frontimg/twitter.png";
import OpenseaLogo from "./assets/frontimg/opensea-logo.png";
import AboutImg from "./assets/frontimg/about.jpg";
import UtilityImg from "./assets/frontimg/drumpf-for-website.png";
import FooterImg1 from "./assets/frontimg/r.png";
import FooterImg2 from "./assets/frontimg/bo.png";
import ptov1 from "./assets/frontimg/ptov1.png";
import ptov2 from "./assets/frontimg/ptov2.png";
import FooterImg3 from "./assets/frontimg/b.png";
import FooterImg4 from "./assets/frontimg/p.png";
import ReactGA from 'react-ga';
import Clock from "./Components/Clock";
ReactGA.initialize('UA-85216838-1');


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Sniper Protection / Button UI");
  const [claimingNft, setClaimingNft] = useState(false);
  const [title, setTitlex] = useState(0);
  const [mintRange, setMintRange] = useState(0);
  const [con, setCon] = useState(false);
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  ReactGA.pageview(window.location.pathname + window.location.search);

  let interval;

  const startTimer = () => {
    const countDownDate = new Date("September 29,2021 18:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Bat...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        //gasLimit: 240000 + (172000 * (_amount - 1)),
        //gasLimit: 180000 + (90500 * (_amount - 1)),

        //to: "0x827acb09a2dc20e39c9aad7f7190d9bc53534192",
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(
          (0.00 * _amount).toFixed(3).toString(),
          "ether"
        ),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback("SUCCESS! Visit Opensea.io to see what you have caught!");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setCon(true);
      console.log(blockchain.account);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Hey.", "I see You!", "I'm glad you made it here...", "because", "we are completley sold out!", "try to get a bat on OpenSea", "probably nothing :)","Welcome To The Community!"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 3000,
      typeSpeed: 30,
      backSpeed: 10,
      backDelay: 1200
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);


 
  return (
    
    <div className="app">
      {/* Desktop Navbar */}
      
      <div className="wnavdesk">
        <Container className="" fluid>
          <Row className="d-flex align-items-center">
            <Col lg="4" className="res-col">
              <div className="d-flex justify-content-around">

                <a href="https://twitter.com/WuhanLab_" target="_blank">
                  <img
                    style={{ height: "42px" }}
                    className="img-fluid  "
                    src={TwitterLogo}
                    alt=""
                  />
                </a>
                <a
                  href="https://opensea.io/collection/whl"
                  target="_blank"
                >
                  <img
                    style={{ height: "42px" }}
                    className="img-fluid  "
                    src={OpenseaLogo}
                    alt=""
                  />
                </a>
              </div>
            </Col>
            <Col lg="4" className="res-col">
              <div className="m-2 d-flex justify-content-center">
                <img className="nav-logo" src={NavLogo} alt="navlogo" />
              </div>
            </Col>
            <Col lg="4" className="res-col c-res">
              <div className=" d-flex justify-content-center">
                {con ? (
                  <button className="btn btn-success">Connected</button>
                ) : (
                  <button onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}className="btn btn-danger">Disconnected</button>
                )}
              </div>
            </Col>
          </Row>
        </Container>                
      </div>
<br></br><br></br>
      <div id="root"></div>
      <div className="endawki justify-content-center align-items-center"><span ref={el}></span></div>
<br></br>
      <div className="endawki justify-content-center align-items-center">! SOLD OUT !<br></br><br></br></div>
      

      <div className="introx">
       <p className="introx-text tinwuv"><br></br><br></br>
      Contract : VERIFIED<br></br>
      0xbcAf5A119Db1EA474F4cA0a24300765035Ab8FEF<br></br>
      <a href="https://etherscan.io/address/0xbcaf5a119db1ea474f4ca0a24300765035ab8fef#code">Mint on Etherscan</a>
       </p>
</div>

      <div className="utility">
        <Container className="con">
          <Row>
            <Col className="" lg="4">
              <div className="text-center ">
                <img
                  className="img-fluid utility-img"
                  src={UtilityImg}
                  alt="utilityimg"
                />
              </div>
            </Col>
            <Col className="rmt" lg="8">
              <div className="">
                <h1 className="text-uppercase title">Utility</h1>
                <p className="utility-text">
                  By owning a bat you become an essential part of WuhanLab.com
                  development. The ultimate goal of this site is to provide a
                  truthful and perminent record of the real world story of the
                  Wuhan Institute of Virology.
                </p>
                <br />
                <p className="utility-text">
                Owning these collectible NFTs gives you proof of sentiment,
                  the ultimate social capital. Owners will have access to premium virtual and physical clubs, access to exclusive collaborative mints, and future incentives within our community and beyond!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer">
        <Container className="con">
          <Row className="mt-5">
            <Col className="" lg="12">
              <div className="">
                <div className="mt-4 d-flex justify-content-center">
                  <div className="w-50 d-flex justify-content-around">
                    
                    <a href="https://twitter.com/WuhanLab_" target="_blank">
                      <img
                        style={{ height: "62px" }}
                        className="img-fluid  fs-icon"
                        src={TwitterLogo}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://opensea.io/collection/whl"
                      target="_blank"
                    >
                      <img
                        style={{ height: "62px" }}
                        className="img-fluid  fs-icon"
                        src={OpenseaLogo}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="d-flex justify-content-between mt-5" fluid>
        <img
          className="img-fluid footer-img"
          src={FooterImg1}
          alt="utilityimg"
        />
        <img
          className="img-fluid footer-img"
          src={FooterImg2}
          alt="utilityimg"
        />
        <img
          className="img-fluid footer-img"
          src={FooterImg3}
          alt="utilityimg"
        />
        <img
          className="img-fluid footer-img"
          src={FooterImg4}
          alt="utilityimg"
        />
      </Container>
    </div>
  );
}

export default App;
